import * as ExcelJS from 'exceljs';
// import { saveAs } from 'file-saver';
import { Injectable } from '@angular/core';
import { DatePipe } from '@angular/common';

@Injectable({
    providedIn: 'root'
})

export class ExportService {
    constructor(private datePipe: DatePipe) { }
    exportToExcel(assumptionsHeaders: string[] = [], assumptionsData: string[][] = [],
        scenariosHeaders: string[] = [], scenariosData: string[][] = []
    ): void {
        //Define your data
        const data1 = [
            assumptionsHeaders,
            assumptionsData.flat()
        ];
        let data2 = [scenariosHeaders];
        for (let i = 0; i < scenariosData.length; i++) {
            data2 = [...data2, scenariosData[i]]
        }
        // Create a workbook and worksheet
        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet("Riders");

        // Freeze the first 4 rows
        worksheet.views = [{ state: "frozen", ySplit: 4 }];

        // Add the first dataset and apply styling to the header
        worksheet.addRows(data1);
        this.applyHeaderStyle(worksheet.getRow(1));

        // Add a blank row for separation
        worksheet.addRow([]);

        // Add the second dataset and apply styling to the header
        const secondDatasetStart = worksheet.addRows(data2);
        this.applyHeaderStyle(secondDatasetStart[0]);

        // Apply data styling
        this.applyDataStyle(worksheet, 2, worksheet.lastRow?.number || 0);

        // Save the Excel file
        workbook.xlsx.writeBuffer().then((buffer) => {
            this.saveExcelFile(buffer, `Riders-${this.datePipe.transform(new Date(), "MM-dd-yyyy")}`);
        });
    }

    private applyDataStyle(worksheet: ExcelJS.Worksheet, startRow: number, endRow: number): void {
        for (let i = startRow; i <= endRow; i++) {
            const row = worksheet.getRow(i);
            row.eachCell((cell) => {
                cell.alignment = { horizontal: "center", vertical: "middle" }; // Center alignment
                cell.border = {
                    top: { style: "thin" },
                    left: { style: "thin" },
                    bottom: { style: "thin" },
                    right: { style: "thin" },
                }; // Add borders
            });
        }
    }

    private applyHeaderStyle(row: ExcelJS.Row): void {
        row.eachCell((cell) => {
            cell.font = { bold: true, color: { argb: "FFFFFF" } }; // Bold and white text
            cell.fill = {
                type: "pattern",
                pattern: "solid",
                fgColor: { argb: "0070C0" }, // Blue background
            };
            cell.alignment = { horizontal: "center" }; // Center alignment
        });
    }
    // exportToMultipleSheets(sheetsData: { sheetName: string; headers: string[]; data: any[] }[], fileName: string): void {
    //     const workbook: XLSX.WorkBook = { Sheets: {}, SheetNames: [] };
    //     sheetsData.forEach((sheet) => {
    //         const worksheetData = [sheet.headers, ...sheet.data];
    //         const worksheet: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(worksheetData);

    //         // Add the worksheet to the workbook
    //         workbook.Sheets[sheet.sheetName] = worksheet;
    //         workbook.SheetNames.push(sheet.sheetName);
    //     });

    //     // Generate the Excel file
    //     const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    //     this.saveExcelFile(excelBuffer, fileName);
    // }

    private saveExcelFile(buffer: any, fileName: string): void {
        const data: Blob = new Blob([buffer], { type: EXCEL_TYPE });
        const url = window.URL.createObjectURL(data);
        const anchor = document.createElement('a');
        anchor.href = url;
        anchor.download = `${fileName}.xlsx`;
        anchor.click();
        URL.revokeObjectURL(url);
    }
}

// MIME type for Excel
const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
