import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})

export class UtilityFunctionService {
    constructor() { }
    
    // Function to convert a number to currency format
    public formatCurrency(value: number): string {
        return value.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
    }

    // Function to convert string to title case
    public toTitleCase(str: string): string {
        return str.replace(/\w\S*/g, (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase());
    }
}
