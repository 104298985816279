import { EventEmitter, Injectable, Output } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';
import { Router } from '@angular/router';

declare var window: any;

@Injectable({
  providedIn: 'root'
})

export class AuthService {
  annuityToken!: number;

  constructor(private http: HttpClient, private router: Router) {
  }

  @Output() isUserLoggedIn: EventEmitter<any> = new EventEmitter();

  private loggedIn: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  get isLoggedIn() {
    return this.loggedIn.asObservable();
  }

  setAccessTokenStorage(access_token: string) {
    sessionStorage.setItem('Token', access_token);
  }

  getAccessToken() {
    return sessionStorage.getItem('Token');
  }

  removeAccessToken() {
    sessionStorage.removeItem('Token');
  }

  isSignedin(): boolean {
    const token = this.getAccessToken();
    return token != null ? true : false;
  }

  login() {
    this.loggedIn.next(true);
  }

  logOut() {
    this.loggedIn.next(false);
  }
}
